<template>
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g id="Line">
      <path style="display:inline;fill:#FF8000;stroke:none;opacity:1" d="M21.53,10.46a5,5,0,0,0-2.2-1.26,4,4,0,1,0-6.8-4.15A4.75,4.75,0,0,0,12,5a4.75,4.75,0,0,0-.53,0A4,4,0,1,0,4.67,9.2a5.08,5.08,0,0,0-2.21,1.27A5,5,0,0,0,1,14a3,3,0,0,0,3,3H5.07A8.26,8.26,0,0,0,5,18a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3,8.26,8.26,0,0,0-.07-1H20a3,3,0,0,0,3-3A5,5,0,0,0,21.53,10.46ZM16,5a2,2,0,0,1,0,4,4,4,0,0,0-1.6-3.18A2,2,0,0,1,16,5ZM14,9a2,2,0,1,1-2-2A2,2,0,0,1,14,9ZM8,5a2,2,0,0,1,1.6.82A4,4,0,0,0,8,9,2,2,0,0,1,8,5ZM4,15a1,1,0,0,1-1-1,3,3,0,0,1,3-3H8.56a4.54,4.54,0,0,0,.48.67A7.05,7.05,0,0,0,5.68,15Zm12,4H8a1,1,0,0,1-1-1,5,5,0,0,1,10,0A1,1,0,0,1,16,19Zm4-4H18.32A7.05,7.05,0,0,0,15,11.67a4.54,4.54,0,0,0,.48-.67H18a3,3,0,0,1,3,3A1,1,0,0,1,20,15Z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Work'
}
</script>
