<template>
<svg version="1.1" id="Layer_2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  width="100px" height="100px" viewBox="-74 0 100 100" style="enable-background:new -74 0 100 100;" xml:space="preserve">
  <path d="M21.2,71.7L9.1,59.5c-2-2-4.7-3.2-7.2-3.2c-2.7,0-5.1,1.1-7.2,3.2l-7,7c-0.3-0.2-0.7-0.4-1-0.5c0,0-0.1,0-0.1-0.1l-0.1-0.1
    c-0.9-0.6-1.9-1.2-3.1-1.7c-6.1-3.8-11.8-9.1-17.6-16.2c-2.7-3.4-4.6-6.4-6.1-9.7l0,0c1.6-1.3,2.8-2.6,3.7-3.6l3.1-3.1
    c2.4-2.2,3.6-4.7,3.6-7.3c0-2.7-1.2-5.2-3.5-7.6l-9.5-9.5c-0.9-0.9-1.8-1.8-2.8-2.7c-2-1.9-4.5-3-7.1-3c-2.9,0-5.3,1-7.5,3.2
    l-7.3,7.3c-2.9,2.7-4.6,6.1-5,10.3l0,0.1c-0.2,5.2,0.6,10,2.8,16c3.5,9.4,8.8,18.2,16.7,27.9C-42.8,77.8-31.2,86.8-18.3,93l0.3,0.1
    c3.8,1.7,11,4.9,19,5.4l1.1,0c5.3,0,9.3-1.7,12.6-5.3c1.2-1.4,2.5-2.7,3.6-3.9c0.9-0.7,1.5-1.4,2.1-2.1l0.8-0.8l0.2-0.2
    C25.6,81.6,25.6,76,21.2,71.7z M17.7,82.8l-1,1L16.6,84c-0.5,0.6-0.9,1.1-1.4,1.5l-0.3,0.3c-1.2,1.2-2.6,2.6-4,4.2
    c-2.3,2.5-4.9,3.6-8.8,3.6H1.3c-7-0.5-13.4-3.3-17.2-5l-0.2-0.1C-28.4,82.6-39.5,74-49.2,63c-7.6-9.2-12.6-17.6-15.9-26.4
    c-2-5.5-2.7-9.5-2.5-14c0.3-2.9,1.4-5.2,3.4-7l7.4-7.4c1.2-1.2,2.4-1.7,3.9-1.7c1.3,0,2.5,0.6,3.6,1.6l0.1,0.1
    c0.9,0.8,1.8,1.7,2.7,2.6l9.5,9.5c1.8,1.8,2.1,3.2,2.1,4c0,0.8-0.2,2-2.1,3.7l-3.2,3.2c-1.1,1.2-2.4,2.5-4.1,3.9l-0.2,0.2l-0.2,0.3
    c-0.9,1.2-1,2.3-0.5,3.9l0.1,0.4l0.1,0.4c1.7,3.7,3.8,7.1,6.8,10.9c6.2,7.6,12.4,13.3,19,17.4l0.3,0.2c0.8,0.4,1.6,0.8,2.3,1.2
    c0.3,0.3,0.7,0.5,1,0.6c0.4,0.2,0.7,0.3,0.9,0.5l0.7,0.5h0.2l0.3,0.1c0.6,0.2,1,0.2,1.3,0.2c1,0,1.9-0.4,2.8-1.3l7.5-7.5
    c1.2-1.2,2.3-1.7,3.7-1.7c1.5,0,2.9,0.9,3.7,1.7l12.2,12.2C20.1,77.7,20.2,80.2,17.7,82.8z"/>
</svg>
</template>

<script>
export default {
  name: 'PhoneLight'
}
</script>
