<template>
  <svg id="Layer_1" style="enable-background:new 0 0 128 128;" version="1.1" viewBox="0 0 128 128" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g>
      <path d="M64,1C29.3,1,1,29.3,1,64s28.3,63,63,63s63-28.3,63-63S98.7,1,64,1z M64,119C33.7,119,9,94.3,9,64S33.7,9,64,9   s55,24.7,55,55S94.3,119,64,119z"/>
      <rect style="display:inline;fill:#FF8000;stroke:#FF8000;opacity:1" height="40" width="8" x="60" y="54.5"/>
      <rect style="display:inline;fill:#FF8000;stroke:#FF8000;opacity:1" height="8" width="8" x="60" y="35.5"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'InfoIcon'
}
</script>
