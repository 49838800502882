<template>
  <div v-if="$store.state.employeeData" class="employee-menu__container" :class="{ active: $store.state.showEmployee }">
    <div @click="closeMenu" class="employee-menu__bg"></div>
      <div class="employee-menu">
        <div class="employee-menu__cont">
        <div class="employee-menu__header">
          <h2>Профиль</h2>
        </div>
        <div class="employee-menu__body">
          <div class="employee-menu__body__main">
            <div class="employee-menu__avatar" :class="{'employee-menu__avatar--active': avatarActive}" @click="avatarActive = !avatarActive">
              <img :class="{ default: !$store.state.employeeData.user_id }" :src="`${baseUrl}/avatar?user=${$store.state.employeeData.user_id}`" alt="user">
            </div>
            <div class="employee-menu__item">
              <div class="employee-menu__item__icon">
                <CabinetIco/>
              </div>
              <div class="employee-menu__item__text">
                <span class="employee-menu__item__text">{{ this.$store.state.employeeData.name || 'Без имени' }}</span>
                <span>ФИО сотрудника</span>
              </div>
            </div>
            <div v-if="$store.state.employeeData.workphone || $store.state.employeeData.personal_phone || $store.state.employeeData.additional_phone" class="employee-menu__item">
              <div class="employee-menu__item__icon">
                <PhoneLight/>
              </div>
              <div class="employee-menu__item__text">
                <span class="employee-menu__phone-list">
                  <span v-if="$store.state.employeeData.workphone" class="tag tag--green" @click="callPhone($store.state.employeeData.workphone)">{{ this.$store.state.employeeData.workphone }}</span>
                  <span v-if="$store.state.employeeData.personal_phone" class="tag tag--green" @click="callPhone($store.state.employeeData.personal_phone)">{{ this.$store.state.employeeData.personal_phone }}</span>
                  <span v-if="$store.state.employeeData.additional_phone" class="tag tag--green" @click="callPhone($store.state.employeeData.additional_phone)">{{ this.$store.state.employeeData.additional_phone }}</span>
                </span>
                <span>Номера телефонов</span>
              </div>
            </div>
            <div v-if="$store.state.employeeData.status" class="employee-menu__item">
              <div class="employee-menu__item__icon">
                <Status/>
              </div>
              <div class="employee-menu__item__text">
                <span>{{ $store.state.employeeData.status }}</span>
                <span>Статус</span>
              </div>
            </div>
            <div v-if="$store.state.employeeData.status" class="employee-menu__item">
              <div class="employee-menu__item__icon employee-menu__item__icon--description">
                <InfoIcon/>
              </div>
              <div class="employee-menu__item__text employee-menu__about-me">
                <span>{{ $store.state.employeeData.description }}</span>
                <span>О себе</span>
              </div>
            </div>
          </div>
          <div class="employee-menu__body__more">
            <div v-if="$store.state.employeeData.post" class="employee-menu__item">
              <div class="employee-menu__item__icon">
                <Post/>
              </div>
              <div class="employee-menu__item__text">
                <span>{{ $store.state.employeeData.post }}</span>
                <span>Должность</span>
              </div>
            </div>
            <div v-if="$store.state.employeeData.email" class="employee-menu__item">
              <div class="employee-menu__item__icon">
                <Email/>
              </div>
              <div class="employee-menu__item__text">
                <span>{{ $store.state.employeeData.email }}</span>
                <span>Электронная почта</span>
              </div>
            </div>
            <div v-if="$store.state.employeeData.birthday" class="employee-menu__item">
              <div style="width: 50px; height: 50px" class="employee-menu__item__icon">
                <Birthday/>
              </div>
              <div class="employee-menu__item__text">
                <span>{{ getDate($store.state.employeeData.birthday) }}</span>
                <span>Дата рождения</span>
              </div>
            </div>
            <div v-if="$store.state.employeeData.department" class="employee-menu__item">
              <div class="employee-menu__item__icon">
                <Work/>
              </div>
              <div class="employee-menu__item__text">
                <span>{{ $store.state.employeeData.department }}</span>
                <span>Отдел</span>
              </div>
            </div>
            <div v-if="$store.state.employeeData.subdivision" class="employee-menu__item">
              <div class="employee-menu__item__icon">
                <Work2/>
              </div>
              <div class="employee-menu__item__text">
                <span>{{ $store.state.employeeData.subdivision }}</span>
                <span>Подразделение</span>
              </div>
            </div>
          </div>
        </div>
        <div class="employee-menu__bottom">
          <button @click="closeMenu" class="btn btn--gray">Закрыть</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import CabinetIco from '@/components/svg/cabinet'
import InfoIcon from '../svg/new/Info.vue'
import Email from '../svg/new/Email.vue'
import Birthday from '../svg/new/Birthday.vue'
import Work from '../svg/new/Work.vue'
import Work2 from '../svg/new/Work2.vue'
import Post from '../svg/new/Post.vue'
import Status from '../svg/new/Status.vue'
import PhoneLight from '../svg/new/PhoneLight.vue'
// import MAKE_CALL from '@/graphql/mutations/MakeCall.gql'
// import GraphQLHelper from '@/helpers/GraphQLHelper'

// const _graphQlHelper = new GraphQLHelper()

export default {
  name: 'Employee',
  components: {
    CabinetIco,
    InfoIcon,
    Email,
    Birthday,
    Work,
    Work2,
    Status,
    Post,
    PhoneLight
  },
  data () {
    return {
      baseUrl: process.env.VUE_APP_HTTP,
      avatarActive: true
    }
  },
  watch: {
    '$route.name' () {
      if (this.$route.name === 'directory') this.loadDirectories()
    }
  },
  computed: {
    allDirectories: {
      get: function () {
        if (this.$store.state.checkedDirectories && this.$store.state.checkedDirectories.length) {
          return this.$store.state.checkedDirectories
        }
        return []
      }
    }
  },
  methods: {
    async callPhone (phone) {
      if (!phone || !this.$store.state.me.workphone) {
        this.$notify({
          group: 'lsg-notify',
          text: 'Не указан телефон'
        })
        return
      }
      const callWindow = window.open(`http://192.168.10.3/amocrm/amocrm.php?_login=amocrm&_secret=mWYd1BU9hmWuezov&_action=call&to=${phone}&as=Amocrm&from=${this.$store.state.me.workphone}&rand=0.9550694509986963`)
      setTimeout(() => {
        callWindow.close()
      }, 1_000)
    },
    getDate (datetime) {
      const date = new Date(datetime)
      const options = {
        month: 'long',
        day: 'numeric'
      }
      return date.toLocaleString('ru', options)
    },
    async loadDirectories () {
      await Promise.all([
        this.$store.dispatch('allDirectories', this)
      ])
    },
    closeMenu () {
      this.$store.state.showEmployee = false
    }
  }
}
</script>

<style lang="stylus">
.employee {
  &-menu__container {
    &.active {
      .employee-menu__bg {
        display block
      }

      .employee-menu {
        transform translateX(0)
      }
    }
  }

  &-menu__bg {
    position fixed
    top 0
    left 0
    right 0
    bottom 0
    z-index 999
    background-color rgba($black, 0.5)
    display none
  }

  &-menu {
    position fixed
    top 0
    right 0
    bottom 0
    z-index 1000
    transition transform 0.3s
    transform translateX(100%)
    display flex
    flex-direction column
    width 100%
    height auto
    max-width 600px
    min-height 100%
    background-color $white

    &__cont {
      overflow-y auto
    }

    &__about-me {
      display flex
      overflow-x none
      word-wrap break-word
      white-space pre-wrap
    }

    &__phone-list {
      display flex
      flex-wrap wrap

      > span.tag {
        cursor pointer
        margin-bottom 5px

        &:not(:last-child) {
          margin-right 10px
        }
      }
    }

    &__item {
      display flex
      flex-direction row
      margin 10px 20px
      min-height 45px

      &__icon {
        width 40px
        height 40px
        display flex
        align-items center
        justify-content center
        flex-shrink 0
        margin-right 20px

        +below(920px) {
          width 30px
          height 30px
        }

        &--description {
          justify-content flex-start
        }

        > svg {
          width 100% !important
          height 100% !important

          max-height 30px
          max-width 30px

          path:not([stroke]):not([fill]) {
            fill $orange
            stroke $orange
          }

          path[stroke] {
            stroke $orange
          }

          path[fill] {
            fill $orange
            stroke-width 20px
          }
        }
      }

      &__text {
        display flex
        flex-direction column
        font-weight: 700
        font-size 18px

        > span:not(:first-child) {
          color: gray
          font-size 12px
        }
      }
    }

    &__avatar {
      margin-bottom 30px
      max-height 300px
      transition: max-height .5s

      > img {
        width: 100%;
        height 300px
        object-fit: cover;
        object-position: center;
        border: 2px solid #f7f9fc;
        // transition: max-height .5s

        &.default {
          object-fit: contain;
        }
      }

      &--active {
        max-height none

        > img {
          height inherit
        }
      }
    }

    &__header {
      font-weight: 500;
      font-size 1.2em
      line-height: 21px;
      padding 27px 30px 32px
      border-bottom 1px solid $pinky
      max-height 100px
    }

    &__body {
      display flex
      flex-direction column
      justify-content space-between
      flex 1

      +below(920px) {
        overflow-y auto
      }

      &__main {
        display flex
        flex-direction column
        border-bottom 1px solid $pinky
        // overflow-y auto
      }

      &__more {
        display flex
        flex-direction column
        // overflow-y auto

        > .employee-menu__item > .employee-menu__item__text {
          font-size 1em !important
        }

        > .employee-menu__item {
          // justify-content center
          align-items center

          > .employee-menu__item__icon {
            width 30px !important
            height 30px !important
          }
        }
      }

      &__add, &__change {
        display flex
        flex-direction column
        align-items center
        height 100%
        overflow-y scroll
      }

      &__change {
        background-color $gray
      }

      &__item {
        &:first-child {
          margin-top 10px
        }
        &:not(:first-child) {
          margin-top 10px
        }
        display inline-block
        width 80%
        height 60px

        &__textarea {
          resize none
        }

        > span {
          display inline-block
          color gray
          margin-bottom 5px
        }
      }
    }

    &__bottom {
      display grid
      grid-template-columns repeat(2, 1fr)
      padding 30px
      gap 16px
      border-top 1px solid $pinky

      button {
        font-size: 15px;
        line-height: 22px;
        text-transform uppercase
      }
    }
  }
}
</style>
