<template>
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 64 64" style="enable-background:new 0 0 64 64;" xml:space="preserve">
<g id="bg" class="st0">
<rect id="XMLID_119_" x="-218" y="-268.2" class="st1" width="500" height="600.3"/>
</g>
<g id="guideline" class="st0">
</g>
<g id="icons">
<g id="XMLID_62_" class="st0">
<polygon id="XMLID_44_" class="st2" points="40.3,52.1 23.7,52.1 22.6,49.7 22.6,42.6 41.4,42.6 41.4,49.6"/>
<rect id="XMLID_29_" x="29.4" y="52.1" class="st2" width="5.3" height="3"/>
<path id="XMLID_25_" class="st2" d="M45.3,28.1c0-7.3-5.9-13.3-13.3-13.3s-13.3,5.9-13.3,13.3c0,4.7,2.4,8.8,6.1,11.1v3.3h14.4
v-3.3C42.9,36.9,45.3,32.8,45.3,28.1z"/>
<line id="XMLID_21_" class="st2" x1="22.6" y1="47.3" x2="30.8" y2="47.3"/>
<g id="XMLID_16_" class="st3">
<path id="XMLID_17_" class="st4" d="M15.5,39.7c-2.1-3.1-3.3-6.9-3.3-11C12.2,17.8,21.1,9,32,9c10.9,0,19.8,8.9,19.8,19.8
c0,4.4-1.5,8.5-3.9,11.8"/>
</g>
<polyline id="XMLID_14_" class="st2" points="26,27.5 30,31.5 38,23.5"/>
</g>
<g id="XMLID_64_" class="st0">

<rect id="XMLID_7_" x="46.1" y="42.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -19.9014 48.0462)" class="st2" width="3.9" height="11"/>
<line id="XMLID_9_" class="st2" x1="41.5" y1="41.5" x2="43.7" y2="43.7"/>
<path id="XMLID_36_" class="st2" d="M45.5,26.2c1.5,5.3,0.2,11.2-4,15.3c-6.1,6.1-16,6.1-22.1,0c-6.1-6.1-6.1-16,0-22.1
c6.1-6.1,16-6.1,22.1,0c0.8,0.8,1.6,1.8,2.2,2.7"/>
<polyline id="XMLID_8_" class="st2" points="16.8,38.9 28.7,26.9 35.3,33.6 51,18"/>
<polyline id="XMLID_2_" class="st2" points="48.5,17.2 51.8,17.2 51.8,20.5"/>
<path id="XMLID_40_" class="st5" d="M39.5,48.1c-2.7,1.4-5.8,2.2-9.1,2.2c-10.9,0-19.8-8.9-19.8-19.8s8.9-19.8,19.8-19.8
c5.4,0,10.3,2.2,13.9,5.7"/>
</g>
<g id="XMLID_99_" class="st0">
<path id="XMLID_77_" class="st2" d="M38.9,40.8V52c0,1.2-1,2.2-2.2,2.2H14.8c-1.2,0-2.2-1-2.2-2.2V12c0-1.2,1-2.2,2.2-2.2h21.9
c1.2,0,2.2,1,2.2,2.2v8.4"/>
<line id="XMLID_68_" class="st2" x1="12.5" y1="16.5" x2="38.9" y2="16.5"/>
<line id="XMLID_69_" class="st2" x1="21.5" y1="13.3" x2="30" y2="13.3"/>
<line id="XMLID_67_" class="st2" x1="12.5" y1="47.5" x2="38.9" y2="47.5"/>
<path id="XMLID_70_" class="st2" d="M49.4,39h-18l-4.9,4.2c0,0-0.1,0-0.1,0v-19c0-1.2,1-2.1,2.1-2.1h20.9c1.2,0,2.1,1,2.1,2.1
v12.7C51.5,38,50.5,39,49.4,39z"/>
<g id="XMLID_76_" class="st3">
<path id="XMLID_72_" class="st6" d="M43.1,26.3h-4.3c-1.2,0-2.1,1-2.1,2.1v0c0,1.2,1,2.1,2.1,2.1h2.1c1.2,0,2.1,1,2.1,2.1l0,0
c0,1.2-1,2.1-2.1,2.1h-4.5"/>
<line id="XMLID_73_" class="st6" x1="39.9" y1="24.1" x2="39.9" y2="26.3"/>
<line id="XMLID_71_" class="st6" x1="39.9" y1="34.9" x2="39.9" y2="37"/>
</g>
</g>
<g id="XMLID_100_" class="st0">
<g id="XMLID_94_" class="st3">
<path id="XMLID_98_" class="st6" d="M34.8,22.2h-3.6c-1,0-1.8,0.8-1.8,1.8l0,0c0,1,0.8,1.8,1.8,1.8h1.7c1,0,1.8,0.8,1.8,1.8l0,0
c0,1-0.8,1.8-1.8,1.8h-3.8"/>
<line id="XMLID_97_" class="st6" x1="32.1" y1="20.4" x2="32.1" y2="22.2"/>
<line id="XMLID_95_" class="st6" x1="32.1" y1="29.4" x2="32.1" y2="31.2"/>
</g>
<path id="XMLID_80_" class="st2" d="M50.5,36.6H13.5c-1.1,0-2-0.9-2-2V16.9c0-1.1,0.9-2,2-2h37.1c1.1,0,2,0.9,2,2v17.7
C52.5,35.7,51.6,36.6,50.5,36.6z"/>
<path id="XMLID_82_" class="st2" d="M17.9,15c0,3.5-2.8,6.4-6.4,6.4"/>
<path id="XMLID_83_" class="st2" d="M46.1,15c0,3.5,2.8,6.4,6.4,6.4"/>
<path id="XMLID_87_" class="st2" d="M17.9,36.6c0-3.5-2.8-6.4-6.4-6.4"/>
<path id="XMLID_86_" class="st2" d="M46.1,36.6c0-3.5,2.8-6.4,6.4-6.4"/>
<circle id="XMLID_85_" class="st2" cx="32" cy="25.8" r="7.5"/>
<line id="XMLID_89_" class="st2" x1="19.8" y1="25.8" x2="22.2" y2="25.8"/>
<line id="XMLID_88_" class="st2" x1="41.5" y1="25.8" x2="43.9" y2="25.8"/>
<line id="XMLID_91_" class="st2" x1="11.5" y1="41.1" x2="52.5" y2="41.1"/>
<line id="XMLID_90_" class="st2" x1="11.5" y1="45" x2="52.5" y2="45"/>
<line id="XMLID_92_" class="st2" x1="11.5" y1="49" x2="52.5" y2="49"/>
</g>
<g id="XMLID_105_" class="st0">
<polygon id="XMLID_145_" class="st2" points="40.3,52.1 23.7,52.1 22.6,49.7 22.6,42.6 41.4,42.6 41.4,49.6"/>
<rect id="XMLID_144_" x="29.4" y="52.1" class="st2" width="5.3" height="3"/>
<path id="XMLID_143_" class="st2" d="M45.3,28.1c0-7.3-5.9-13.3-13.3-13.3s-13.3,5.9-13.3,13.3c0,4.7,2.4,8.8,6.1,11.1v3.3h14.4
v-3.3C42.9,36.9,45.3,32.8,45.3,28.1z"/>
<line id="XMLID_142_" class="st2" x1="22.6" y1="47.3" x2="30.8" y2="47.3"/>
<g id="XMLID_135_" class="st3">
<path id="XMLID_136_" class="st4" d="M15.5,39.7c-2.1-3.1-3.3-6.9-3.3-11C12.2,17.8,21.1,9,32,9c10.9,0,19.8,8.9,19.8,19.8
c0,4.4-1.5,8.5-3.9,11.8"/>
</g>
<g id="XMLID_146_" class="st3">
<path id="XMLID_148_" class="st6" d="M41.3,29.2l0-2l-3.2-0.5c-0.2-0.7-0.4-1.3-0.8-1.9l1.9-2.6l-1.4-1.4l-2.6,1.9
c-0.6-0.4-1.2-0.6-1.9-0.8L33,18.9h-2L30.6,22c-0.7,0.2-1.3,0.4-1.9,0.8l-2.6-1.9l-1.4,1.4l1.9,2.6c-0.4,0.6-0.6,1.2-0.8,1.9
l-3.2,0.5l0,2l3.2,0.5c0.2,0.7,0.4,1.3,0.8,1.9l-1.9,2.6l1.4,1.4l2.6-1.9c0.6,0.4,1.2,0.6,1.9,0.8l0.5,3.2l2,0l0.5-3.2
c0.7-0.2,1.3-0.4,1.9-0.8l2.6,1.9l1.4-1.4l-1.9-2.6c0.4-0.6,0.6-1.2,0.8-1.9L41.3,29.2z"/>
<circle id="XMLID_147_" class="st6" cx="32" cy="28.2" r="2.8"/>
</g>
</g>
<g id="XMLID_150_" class="st0">
<g id="XMLID_84_" class="st3">
<path id="XMLID_107_" class="st6" d="M32.1,35.8l0-2.4l-3.8-0.5c-0.2-0.8-0.5-1.5-0.9-2.2l2.3-3L28,25.9l-3,2.3
c-0.7-0.4-1.4-0.7-2.2-0.9l-0.5-3.8h-2.4l-0.5,3.8c-0.8,0.2-1.5,0.5-2.2,0.9l-3-2.3l-1.7,1.7l2.3,3c-0.4,0.7-0.7,1.4-0.9,2.2
l-3.8,0.5l0,2.4l3.8,0.6c0.2,0.8,0.5,1.5,0.9,2.2l-2.3,3l1.7,1.7l3-2.3c0.7,0.4,1.4,0.7,2.2,0.9l0.5,3.8l2.4,0l0.5-3.8
c0.8-0.2,1.5-0.5,2.2-0.9l3,2.3l1.7-1.7l-2.3-3c0.4-0.7,0.7-1.4,0.9-2.2L32.1,35.8z"/>
<circle id="XMLID_81_" class="st6" cx="21" cy="34.6" r="3.4"/>
</g>
<path id="XMLID_93_" class="st2" d="M54.1,48.9c0-5.2-7.6-3.9-7.6-3.9V11.3h3.8c2.1,0,3.8,1.7,3.8,3.8v27V48.9z"/>
<path id="XMLID_102_" class="st2" d="M20.9,21v-2.2c0-1,0.8-1.8,1.8-1.8h21.4"/>
<path id="XMLID_96_" class="st2" d="M54.1,42.3v6.1c0,2.4-1.9,4.3-4.3,4.3h-27c-1,0-1.8-0.8-1.8-1.8V48"/>
<path id="XMLID_104_" class="st5" d="M28.3,21c5.8,2,9.9,7.5,9.9,14c0,6-3.6,11.2-8.8,13.5"/>
</g>
<g id="XMLID_112_" class="st0">
<g id="XMLID_128_" class="st3">
<path id="XMLID_133_" class="st6" d="M25.2,36.2h-5c-1.4,0-2.5,1.1-2.5,2.5l0,0c0,1.4,1.1,2.5,2.5,2.5h2.4c1.4,0,2.5,1.1,2.5,2.5
l0,0c0,1.4-1.1,2.5-2.5,2.5h-5.2"/>
<line id="XMLID_132_" class="st6" x1="21.5" y1="33.6" x2="21.5" y2="36.2"/>
<line id="XMLID_131_" class="st6" x1="21.5" y1="46.2" x2="21.5" y2="48.6"/>
</g>
<circle id="XMLID_120_" class="st2" cx="21.3" cy="41.1" r="12"/>
<line id="XMLID_108_" class="st2" x1="12.1" y1="41.1" x2="15" y2="41.1"/>
<line id="XMLID_106_" class="st2" x1="27.6" y1="41.1" x2="30.5" y2="41.1"/>
<path id="XMLID_121_" class="st2" d="M39.9,30.3v-7h5.4c0.2,0,0.3-0.2,0.1-0.3L33.3,10.8c-0.1-0.1-0.2-0.1-0.3,0L20.8,23
c-0.1,0.1,0,0.3,0.1,0.3h5.4v4.5"/>
<path id="XMLID_117_" class="st2" d="M50.4,53.2V41.5h4.2c0.1,0,0.2-0.2,0.1-0.3l-9.4-9.4c-0.1-0.1-0.2-0.1-0.2,0l-2.4,2.4"/>
<line id="XMLID_110_" class="st5" x1="39.9" y1="52.1" x2="39.9" y2="30.3"/>
</g>
<g id="XMLID_123_" class="st0">
<path id="XMLID_109_" class="st2" d="M53.3,31.8c0,0.1,0,0.1,0,0.2C52.2,33.2,40.7,45.6,32,45.6c-8.7,0-20.2-12.5-21.3-13.7
c0-0.1,0-0.1,0-0.2c1.1-1.2,12.6-13.7,21.3-13.7C40.7,18.1,52.2,30.6,53.3,31.8z"/>
<path id="XMLID_114_" class="st2" d="M41.4,34.6c-1.1,4.2-5,7.3-9.6,7.3c-5.5,0-9.9-4.4-9.9-9.9c0-4.6,3.1-8.4,7.4-9.6"/>
<polyline id="XMLID_113_" class="st2" points="31.8,20 31.8,32 44.5,32"/>
<path id="XMLID_111_" class="st2" d="M31.6,22.1c5.5,0,9.9,4.4,9.9,9.9"/>
<line id="XMLID_116_" class="st2" x1="31.9" y1="11.1" x2="31.9" y2="15.4"/>
<line id="XMLID_122_" class="st2" x1="42.2" y1="13.8" x2="40" y2="17.5"/>
<line id="XMLID_115_" class="st2" x1="21.5" y1="14" x2="23.6" y2="17.7"/>
<line id="XMLID_126_" class="st2" x1="31.9" y1="52.9" x2="31.9" y2="48.6"/>
<line id="XMLID_125_" class="st2" x1="42.2" y1="50.2" x2="40" y2="46.5"/>
<line id="XMLID_124_" class="st2" x1="21.5" y1="50" x2="23.6" y2="46.3"/>
<path id="XMLID_137_" class="st2" d="M36.5,32c0,2.6-2.1,4.7-4.7,4.7s-4.7-2.1-4.7-4.7c0-2.6,2.1-4.7,4.7-4.7"/>
</g>
<g id="XMLID_161_">
<path id="XMLID_153_" class="st6" d="M14.4,55V42c0-0.9,0.6-1.6,1.4-1.9L27,36.2v-4.3"/>
<path id="XMLID_157_" class="st6" d="M49.6,55V42c0-0.9-0.6-1.6-1.4-1.9L37,36.2v-4.3"/>
<path id="XMLID_162_" class="st6" d="M40.4,19.3l0.1-1.8c0-4.7-3.8-8.5-8.5-8.5c-4.7,0-8.5,3.8-8.5,8.5l0.1,1.8h-0.1
c-1,0-1.7,0.9-1.5,1.9l0.1,0.3c0.2,0.9,1,1.6,1.9,1.7l0.4,4.5c0.1,1.4,0.9,2.6,2.1,3.3l4.1,2.7h2.7l4.3-2.7c1.2-0.6,2-1.9,2.1-3.3
l0.4-4.5c0.8-0.2,1.4-0.9,1.6-1.7l0.1-0.3C42,20.3,41.3,19.4,40.4,19.3z"/>
<path id="XMLID_151_" class="st6" d="M30.2,43.9l-1.1-3.3c0,0,0,0,0,0l2.8-2c0,0,0,0,0,0l2.8,2c0,0,0,0,0,0l-1.1,3.3H30.2z"/>
<line id="XMLID_160_" class="st6" x1="30.2" y1="43.9" x2="29.1" y2="55"/>
<line id="XMLID_156_" class="st6" x1="33.4" y1="43.9" x2="34.5" y2="55"/>
<line id="XMLID_163_" class="st6" x1="27" y1="36.2" x2="30.2" y2="39.8"/>
<line id="XMLID_164_" class="st6" x1="37.2" y1="36.2" x2="34" y2="39.8"/>
<polyline id="XMLID_165_" class="st6" points="19.4,48.3 25.4,48.3 25.4,52.3 24,54 22.4,54"/>
<line id="XMLID_166_" class="st6" x1="23.4" y1="45.3" x2="23.4" y2="48.3"/>
</g>
<g id="XMLID_179_" class="st0">
<g id="XMLID_169_" class="st3">
<path id="XMLID_174_" class="st6" d="M27.4,24.3H11.5c-0.4,0-0.8,0.4-0.8,0.8v24.3"/>
<line id="XMLID_173_" class="st6" x1="49.6" y1="49.4" x2="49.6" y2="34.7"/>
<polyline id="XMLID_175_" class="st6" points="27.4,27.6 14.4,27.6 14.4,47.5"/>
<line id="XMLID_172_" class="st6" x1="45.9" y1="47.5" x2="45.9" y2="37.4"/>
<path id="XMLID_797_" class="st6" d="M49.9,52.9H10.2c-0.5,0-0.9-0.4-1-0.9l-0.4-2.3h17.6l0.7,1H32l0.7-1h18.6L50.9,52
C50.8,52.5,50.4,52.9,49.9,52.9z"/>
</g>
<path id="XMLID_443_" class="st2" d="M29.8,37.4l5.5-1c1.8,0.8,3.8,1.1,5.9,0.8c4.8-0.7,8.6-4.6,9.2-9.4c0.8-7-5.1-12.8-12.1-12
c-4.8,0.6-8.7,4.5-9.3,9.3c-0.3,2.6,0.3,5.1,1.6,7.1l-0.9,5C29.6,37.3,29.7,37.4,29.8,37.4z"/>
<path id="XMLID_171_" class="st2" d="M42.6,26.6l-5.6,3.3c-0.1,0-0.1,0-0.1-0.1v-6.5c0-0.1,0.1-0.1,0.1-0.1l5.6,3.3
C42.6,26.5,42.6,26.6,42.6,26.6l-5.6,3.3c-0.1,0-0.1,0-0.1-0.1v-6.5c0-0.1,0.1-0.1,0.1-0.1l5.6,3.3C42.6,26.5,42.6,26.6,42.6,26.6
z"/>
<path id="XMLID_178_" class="st2" d="M39.7,11.1c8.5,0,15.5,6.9,15.5,15.5c0,3.4-1.1,6.6-3,9.2"/>
<path id="XMLID_177_" class="st5" d="M25.1,21.4c2.1-6,7.9-10.3,14.6-10.3"/>
</g>
<g id="XMLID_180_" class="st0">
<g id="XMLID_257_" class="st3">
<path id="XMLID_272_" class="st6" d="M28.2,54.6H12v-1.1c0-1.2,1-2.2,2.2-2.2H29"/>
<path id="XMLID_194_" class="st6" d="M31,33.1v6.3c0,1.3,0.1,2.5,0.4,3.8"/>
<path id="XMLID_191_" class="st6" d="M14.3,51.3L14.3,51.3c2.6-3.4,4-7.6,4-11.9v-6.3"/>
<path id="XMLID_267_" class="st6" d="M32.3,22.5h-1.8c-0.2,0-0.4,0.2-0.4,0.4v2.4h-4.5v-2.4c0-0.2-0.2-0.4-0.4-0.4h-2.7
c-0.2,0-0.4,0.2-0.4,0.4v2.4h-4.5v-2.4c0-0.2-0.2-0.4-0.4-0.4h-1.8c-0.2,0-0.4,0.2-0.4,0.4v2.4v2.9v4.9c0,0,0,0,0,0h18.7"/>
</g>
<g id="XMLID_189_" class="st3">
<path id="XMLID_183_" class="st6" d="M36.6,30c0,0,3.7,3.5,0,8.5c-1.9,2.6-6.1,6.8-2.7,12.8"/>
<path id="XMLID_188_" class="st6" d="M45.9,30c0,0-3.7,3.5,0,8.5c1.9,2.6,6.1,6.8,2.7,12.8"/>
<path id="XMLID_182_" class="st6" d="M52,54.8H30.6V53c0-1,0.8-1.9,1.9-1.9h17.7c1,0,1.9,0.8,1.9,1.9V54.8z"/>
<rect id="XMLID_187_" x="34.2" y="26.9" class="st6" width="14.5" height="3.2"/>
<path id="XMLID_190_" class="st6" d="M37.9,26.9c-2.2,0-4-1.9-3.7-4.2c0.2-1.6,1.5-3,3.1-3.2c0.9-0.2,1.8,0,2.6,0.5
c0,0,0.1,0,0.1,0v-3.4h-2.8c-0.1,0-0.1,0-0.1-0.1v-3.5c0-0.1,0-0.1,0.1-0.1h2.8V9.3c0-0.1,0-0.1,0.1-0.1h2.8c0.1,0,0.1,0,0.1,0.1
v3.5h2.8c0.1,0,0.1,0,0.1,0.1v3.5c0,0.1,0,0.1-0.1,0.1h-2.8v3.4c0,0,0,0,0.1,0c0.7-0.4,1.6-0.6,2.6-0.5c1.6,0.3,2.9,1.6,3.1,3.2
c0.3,2.3-1.5,4.2-3.7,4.2"/>
</g>
</g>
<g id="XMLID_217_" class="st0">
<g id="XMLID_206_" class="st3">
<path id="XMLID_209_" class="st6" d="M40.7,32.9l0-1.9l-3-0.4c-0.1-0.6-0.4-1.2-0.7-1.8l1.8-2.4l-1.3-1.3L35.1,27
c-0.5-0.3-1.1-0.6-1.8-0.7l-0.4-3h-1.9l-0.4,3c-0.6,0.1-1.2,0.4-1.7,0.7l-2.4-1.8l-1.3,1.3l1.8,2.4c-0.3,0.5-0.6,1.1-0.7,1.8
l-3,0.4l0,1.9l3,0.4c0.1,0.6,0.4,1.2,0.7,1.8l-1.8,2.4l1.3,1.3l2.4-1.8c0.5,0.3,1.1,0.6,1.8,0.7l0.4,3l1.9,0l0.4-3
c0.6-0.1,1.2-0.4,1.8-0.7l2.4,1.8l1.3-1.3L37,35.1c0.3-0.5,0.6-1.1,0.7-1.8L40.7,32.9z"/>
<circle id="XMLID_207_" class="st6" cx="32" cy="32" r="2.7"/>
</g>
<circle id="XMLID_186_" class="st2" cx="32" cy="32" r="18.5"/>
<path id="XMLID_214_" class="st5" d="M50.6,19.4c2.4,3.6,3.9,7.9,3.9,12.6c0,4.7-1.4,9.1-3.9,12.7"/>
<path id="XMLID_218_" class="st5" d="M20,13c3.5-2.2,7.6-3.5,12-3.5c4.7,0,9,1.4,12.6,3.9"/>
<path id="XMLID_216_" class="st5" d="M13.4,44.6C10.9,41,9.5,36.7,9.5,32c0-5,1.6-9.6,4.4-13.4"/>
<path id="XMLID_213_" class="st5" d="M43.6,51.3c-3.4,2-7.4,3.2-11.6,3.2c-5.2,0-9.9-1.7-13.7-4.7"/>
<circle id="XMLID_193_" class="st2" cx="32" cy="32" r="11.9"/>
<line id="XMLID_197_" class="st2" x1="16.9" y1="21.4" x2="21.2" y2="25.7"/>
<line id="XMLID_196_" class="st2" x1="21.2" y1="17.1" x2="25.5" y2="21.4"/>
<line id="XMLID_203_" class="st2" x1="42.6" y1="47.1" x2="38" y2="42.5"/>
<line id="XMLID_202_" class="st2" x1="46.9" y1="42.8" x2="42.3" y2="38.2"/>
<line id="XMLID_200_" class="st2" x1="47.3" y1="21.4" x2="42.9" y2="25.8"/>
<line id="XMLID_199_" class="st2" x1="43" y1="17.1" x2="38.6" y2="21.5"/>
<line id="XMLID_201_" class="st2" x1="26" y1="42.7" x2="21.4" y2="47.3"/>
<line id="XMLID_198_" class="st2" x1="21.7" y1="38.4" x2="17.1" y2="43"/>
<path id="XMLID_210_" class="st2" d="M16.3,18.2l-1.7-1.7c-0.7-0.7-0.7-1.8,0-2.4l0,0c0.7-0.7,1.8-0.7,2.4,0"/>
<path id="XMLID_204_" class="st2" d="M45.9,16.3l1.7-1.7c0.7-0.7,1.8-0.7,2.4,0l0,0c0.7,0.7,0.7,1.8,0,2.4"/>
<path id="XMLID_211_" class="st2" d="M47.7,45.9l1.7,1.7c0.7,0.7,0.7,1.8,0,2.4l0,0c-0.7,0.7-1.8,0.7-2.4,0"/>
<path id="XMLID_212_" class="st2" d="M18.1,47.7l-1.7,1.7c-0.7,0.7-1.8,0.7-2.4,0l0,0c-0.7-0.7-0.7-1.8,0-2.4"/>
</g>
<g id="XMLID_394_" class="st0">
<g id="XMLID_375_" class="st3">
<path id="XMLID_379_" class="st6" d="M11.9,54v-2.4c0-0.5,0.3-0.9,0.7-1l5.9-2v-3.3"/>
<path id="XMLID_378_" class="st6" d="M30.3,54v-2.4c0-0.5-0.3-0.9-0.7-1l-5.9-2v-3.3"/>
<path id="XMLID_377_" class="st6" d="M25.6,37.8c0-2.5-2-4.4-4.4-4.4c-2.5,0-4.4,2-4.4,4.4l0.4,5.3c0.1,0.7,0.5,1.4,1.1,1.7
l2.2,1.4h1.4l2.2-1.4c0.6-0.3,1.1-1,1.1-1.7L25.6,37.8z"/>
<polyline id="XMLID_376_" class="st6" points="18.5,48.5 20.2,50.4 22.2,50.4 23.8,48.5"/>
</g>
<g id="XMLID_381_" class="st3">
<path id="XMLID_386_" class="st6" d="M33.7,54v-2.2c0-0.5,0.3-0.9,0.7-1l5.9-2v-3.3"/>
<path id="XMLID_384_" class="st6" d="M52.1,54v-2.2c0-0.5-0.3-0.9-0.7-1l-5.9-2v-3.3"/>
<path id="XMLID_383_" class="st6" d="M47.3,38c0-2.5-2-4.4-4.4-4.4c-2.5,0-4.4,2-4.4,4.4l0.4,5.3c0.1,0.7,0.5,1.4,1.1,1.7
l2.2,1.4h1.4l2.2-1.4c0.6-0.3,1.1-1,1.1-1.7L47.3,38z"/>
<polyline id="XMLID_382_" class="st6" points="40.3,48.8 41.9,50.7 43.9,50.7 45.6,48.8"/>
</g>
<g id="XMLID_387_" class="st3">
<polyline id="XMLID_392_" class="st6" points="27,43 29.5,42.2 29.5,39.1"/>
<polyline id="XMLID_391_" class="st6" points="36.8,43.1 34.4,42.2 34.4,39.1"/>
<path id="XMLID_390_" class="st6" d="M36.2,32c0-2.3-1.9-4.2-4.2-4.2c-2.3,0-4.2,1.9-4.2,4.2l0.4,5.1c0,0.7,0.4,1.3,1.1,1.6
l2,1.3h1.3l2.1-1.3c0.6-0.3,1-0.9,1.1-1.6L36.2,32z"/>
<polyline id="XMLID_389_" class="st6" points="29.5,42.2 31.1,44 32.9,44 34.5,42.2"/>
</g>
<g id="XMLID_412_" class="st3">
<path id="XMLID_393_" class="st6" d="M46.8,32.4l0-3.2l-5-0.7c-0.3-1.1-0.7-2.1-1.2-3l3.1-4.1l-2.2-2.2l-4.1,3.1
c-0.9-0.6-1.9-1-3-1.2l-0.7-5.1h-3.2l-0.7,5.1c-1.1,0.3-2.1,0.7-3,1.2l-4.1-3.1l-2.3,2.2l3,4.1c-0.6,0.9-1,1.9-1.2,3l-5.1,0.7
l0,3.2"/>
</g>
<path id="XMLID_413_" class="st5" d="M13.8,38.1c-1.2-2.6-1.9-5.4-1.9-8.4C12,18.8,20.8,10,31.6,10c10.9,0,19.7,8.8,19.7,19.7
c0,2.9-0.6,5.6-1.7,8"/>
</g>
<g id="XMLID_224_" class="st0">
<path id="XMLID_219_" class="st2" d="M31.2,35.8H25c-0.4,0-0.7-0.3-0.7-0.7v-1.5c0-0.4,0.2-0.9,0.6-1.1l4.6-3.4
c0.4-0.3,0.7-0.8,0.7-1.3v-0.7c0-0.7-0.6-1.3-1.3-1.3h-3.3c-0.7,0-1.3,0.6-1.3,1.3l0,0v0.5"/>
<path id="XMLID_220_" class="st2" d="M37.9,36.7V25.8c0,0,0,0,0,0h-1.1c0,0,0,0,0,0L32,31.6c0,0,0,0.1,0,0.1h8.7"/>
<path id="XMLID_221_" class="st2" d="M31.3,53.4h-4.2c-0.9,0-1.6-0.7-1.6-1.6l0,0c0-0.9,0.7-1.6,1.6-1.6h4.2
c0.9,0,1.6,0.7,1.6,1.6l0,0C32.9,52.7,32.2,53.4,31.3,53.4z"/>
<path id="XMLID_223_" class="st2" d="M16.5,35.9h-3.4c-1.6,0-3-1.3-3-3v-4.4c0-1.6,1.3-3,3-3h3.4V35.9z"/>
<path id="XMLID_225_" class="st2" d="M47.5,35.9h3.4c1.6,0,3-1.3,3-3v-4.4c0-1.6-1.3-3-3-3h-3.4V35.9z"/>
<path id="XMLID_226_" class="st5" d="M47.2,22.8c-1.5-7-7.8-12.2-15.2-12.2l0,0c-7.9,0-14.5,6-15.4,13.7"/>
<path id="XMLID_222_" class="st2" d="M32,51.8c8.5,0,15.5-7,15.5-15.5v-3.2"/>
</g>
<g id="XMLID_292_" class="st0">
<g id="XMLID_234_" class="st3">
<g id="XMLID_243_">
<path id="XMLID_247_" class="st6" d="M41.5,15.7h-4.8c-1.3,0-2.4,1.1-2.4,2.4l0,0c0,1.3,1.1,2.4,2.4,2.4H39
c1.3,0,2.4,1.1,2.4,2.4v0c0,1.3-1.1,2.4-2.4,2.4h-4.9"/>
<line id="XMLID_246_" class="st6" x1="38" y1="13.2" x2="38" y2="15.7"/>
<line id="XMLID_245_" class="st6" x1="38" y1="25.1" x2="38" y2="27.4"/>
</g>
<circle id="XMLID_242_" class="st6" cx="37.8" cy="20.3" r="11.3"/>
<line id="XMLID_240_" class="st6" x1="29.2" y1="20.3" x2="31.8" y2="20.3"/>
<line id="XMLID_239_" class="st6" x1="43.8" y1="20.3" x2="46.5" y2="20.3"/>
</g>
<path id="XMLID_417_" class="st2" d="M9,51h8.6c0,0,0.1,0,0.1-0.1V33.1c0,0,0-0.1-0.1-0.1H9"/>
<path id="XMLID_228_" class="st2" d="M17.7,36l11.2,0c0.7,0.1,1.6,0.5,2.2,1l7.3,6.8c2.1,1.6-0.1,5.1-2.1,3.3l-7.5-5.4l0,2.6
l7,6.6c0,0,4.2,0.4,4.8,0.5l7.8-3.1c1.3-0.5,2.8-0.6,4.1-0.1c1,0.4,1.9,0.8,2.1,1.3l-13.4,5.4c-0.5,0.2-7.7,0.2-7.7,0.2l-15.6-7.6
"/>
<path id="XMLID_230_" class="st2" d="M36.5,42.4c4.3,0,7.9,3.3,8.4,7.4"/>
<line id="XMLID_233_" class="st2" x1="37.8" y1="31.6" x2="37.8" y2="42.8"/>
<path id="XMLID_231_" class="st2" d="M45.3,36.2c0,0-1.2,3.2-2.6,3.8c-1.5,0.6-4.6-0.9-4.6-0.9s1.2-3.2,2.6-3.8
C42.2,34.7,45.3,36.2,45.3,36.2z"/>
<line id="XMLID_236_" class="st2" x1="20.6" y1="20.4" x2="24.7" y2="20.4"/>
<line id="XMLID_235_" class="st2" x1="21.6" y1="14.5" x2="25.4" y2="15.9"/>
<line id="XMLID_252_" class="st2" x1="53.9" y1="14.3" x2="50.1" y2="15.7"/>
<line id="XMLID_253_" class="st2" x1="55" y1="20.2" x2="50.9" y2="20.2"/>
<line id="XMLID_254_" class="st2" x1="53.9" y1="26.1" x2="50.2" y2="24.7"/>
<line id="XMLID_262_" class="st2" x1="21.7" y1="26.2" x2="25.5" y2="24.8"/>
</g>
<g id="XMLID_293_" class="st0">
<g id="XMLID_316_" class="st3">
<path id="XMLID_319_" class="st6" d="M35.1,19.8h-4c-1.1,0-2,0.9-2,2l0,0c0,1.1,0.9,2,2,2h1.9c1.1,0,2,0.9,2,2v0c0,1.1-0.9,2-2,2
h-4.1"/>
<line id="XMLID_318_" class="st6" x1="32.2" y1="17.8" x2="32.2" y2="19.8"/>
<line id="XMLID_317_" class="st6" x1="32.2" y1="27.7" x2="32.2" y2="29.6"/>
</g>
<circle id="XMLID_315_" class="st2" cx="32" cy="23.7" r="9.4"/>
<path id="XMLID_298_" class="st7" d="M21.2,15.5c2.6-3.7,7-6.1,11.9-5.9c4.9,0.2,9,2.8,11.4,6.6"/>
<line id="XMLID_314_" class="st2" x1="24.8" y1="23.7" x2="27.1" y2="23.7"/>
<line id="XMLID_313_" class="st2" x1="37.1" y1="23.7" x2="39.3" y2="23.7"/>
<path id="XMLID_310_" class="st2" d="M48.9,54.4v-7.2c0,0,0-0.1-0.1-0.1H33.9c0,0-0.1,0-0.1,0.1v7.2"/>
<path id="XMLID_309_" class="st2" d="M36.3,47.1l0-9.3c0.1-0.6,0.5-1.4,0.9-1.8l5.7-6.1c1.4-1.8,4.3,0.1,2.7,1.7l-4.5,6.3l2.2,0
l5.5-5.8c0,0,0.3-3.5,0.5-4l-2.6-6.5c-0.4-1.1-0.5-2.3-0.1-3.4c0.3-0.8,0.7-1.5,1.1-1.7l4.5,11.2c0.2,0.4,0.1,6.4,0.1,6.4l-6.3,13
"/>
<path id="XMLID_297_" class="st2" d="M15.1,54.4v-7.2c0,0,0-0.1,0.1-0.1h14.9c0,0,0.1,0,0.1,0.1v7.2"/>
<path id="XMLID_296_" class="st2" d="M27.7,47.1l0-9.3c-0.1-0.6-0.5-1.4-0.8-1.8l-5.7-6.1c-1.4-1.8-4.3,0.1-2.7,1.7l4.5,6.3
l-2.2,0l-5.5-5.8c0,0-0.3-3.5-0.5-4l2.6-6.5c0.4-1.1,0.5-2.3,0.1-3.4c-0.3-0.8-0.7-1.5-1.1-1.7l-4.5,11.2
c-0.2,0.4-0.1,6.4-0.1,6.4l6.3,13"/>
</g>
<g id="XMLID_248_" class="st0">
<path id="XMLID_256_" class="st2" d="M12.4,24.1l-1.9,3.3l21.4,24.4c0.1,0.1,0.2,0.1,0.2,0l21.4-24.4l-5.4-9.3H21.6"/>
<line id="XMLID_255_" class="st2" x1="10.4" y1="27.4" x2="53.6" y2="27.4"/>
<line id="XMLID_485_" class="st2" x1="32" y1="51.9" x2="22.1" y2="29.4"/>
<line id="XMLID_484_" class="st2" x1="32" y1="51.4" x2="41.6" y2="29.4"/>
<line id="XMLID_791_" class="st2" x1="32" y1="29.4" x2="32" y2="51.4"/>
<polyline id="XMLID_238_" class="st2" points="27.2,17.7 21.5,25.5 20,23.5"/>
<polyline id="XMLID_250_" class="st2" points="37.5,17.7 31.8,25.5 26.7,18.9"/>
<polyline id="XMLID_251_" class="st2" points="47.7,17.7 42.1,25.5 37,18.9"/>
<g id="XMLID_249_" class="st3">
<line id="XMLID_258_" class="st6" x1="15.7" y1="12.1" x2="15.7" y2="14.9"/>
<line id="XMLID_259_" class="st6" x1="20.9" y1="15.1" x2="18.5" y2="16.5"/>
<line id="XMLID_261_" class="st6" x1="20.9" y1="21.1" x2="18.5" y2="19.7"/>
<line id="XMLID_265_" class="st6" x1="15.7" y1="24.1" x2="15.7" y2="21.3"/>
<line id="XMLID_269_" class="st6" x1="10.5" y1="21.1" x2="12.9" y2="19.7"/>
<line id="XMLID_271_" class="st6" x1="10.5" y1="15.1" x2="12.9" y2="16.5"/>
</g>
</g>
<g id="XMLID_48_" class="st0">
<polygon id="XMLID_54_" class="st2" points="31.3,49.7 14.2,49.7 13,47.2 13,39.9 32.5,39.9 32.5,47.1"/>
<rect id="XMLID_53_" x="20" y="49.7" class="st2" width="5.5" height="3.1"/>
<path id="XMLID_57_" class="st2" d="M36.3,22.4c-1.2-6.3-6.8-11.1-13.5-11.1c-7.6,0-13.7,6.1-13.7,13.7c0,4.8,2.5,9.1,6.3,11.5
v3.4h14.9v-3.4c3.1-2,5.4-5.2,6.1-9"/>
<line id="XMLID_51_" class="st2" x1="13" y1="44.8" x2="21.5" y2="44.8"/>
<line id="XMLID_49_" class="st2" x1="47.3" y1="20.8" x2="47.3" y2="28.3"/>
<path id="XMLID_13_" class="st2" d="M47.4,31.8h-0.3c-2,0-3.7-1.6-3.7-3.7V21c0-2,1.6-3.7,3.7-3.7h0.3c2,0,3.7,1.6,3.7,3.7v7.1
C51.1,30.2,49.5,31.8,47.4,31.8z"/>
<path id="XMLID_50_" class="st2" d="M51.1,20.8c2.1,0,3.8,1.7,3.8,3.8s-1.7,3.8-3.8,3.8"/>
<line id="XMLID_55_" class="st2" x1="22.8" y1="24.6" x2="44" y2="24.6"/>
<path id="XMLID_141_" class="st2" d="M26.6,22.1c0-2.1-1.7-3.8-3.8-3.8c-2.1,0-3.8,1.7-3.8,3.8c0,1.3,0.7,2.5,1.7,3.2v6.1h4.2
v-6.1C25.9,24.6,26.6,23.4,26.6,22.1z"/>
</g>
<g id="XMLID_289_" class="st0">
<path id="XMLID_152_" class="st2" d="M50,50.6v-2.8c0,0,0,0,0,0H14.3c0,0,0,0,0,0v2.8h-2.5c0,0-0.1,0-0.1,0.1v3.7h40.8v-3.7
c0,0,0-0.1-0.1-0.1H50z"/>
<line id="XMLID_74_" class="st2" x1="16.2" y1="50.6" x2="32.8" y2="50.6"/>
<polyline id="XMLID_139_" class="st2" points="15.7,47.4 15.7,44.6 23.4,44.6 23.4,47.4"/>
<polyline id="XMLID_52_" class="st2" points="28.3,47.4 28.3,44.6 36,44.6 36,47.4"/>
<polyline id="XMLID_154_" class="st2" points="41,47.4 41,44.6 48.6,44.6 48.6,47.4"/>
<polyline id="XMLID_208_" class="st2" points="15.7,23.9 15.7,26.8 23.4,26.8 23.4,23.9"/>
<polyline id="XMLID_286_" class="st2" points="12,21.7 12,23.8 12,23.8 51.6,23.8 51.6,23.8 51.6,20.9"/>
<polyline id="XMLID_192_" class="st2" points="28.3,23.9 28.3,26.8 36,26.8 36,23.9"/>
<polyline id="XMLID_184_" class="st2" points="41,23.9 41,26.8 48.6,26.8 48.6,23.9"/>
<line id="XMLID_229_" class="st2" x1="17.3" y1="26.8" x2="17.3" y2="44.6"/>
<line id="XMLID_167_" class="st2" x1="22" y1="26.8" x2="22" y2="44.6"/>
<line id="XMLID_274_" class="st2" x1="29.8" y1="26.8" x2="29.8" y2="44.6"/>
<line id="XMLID_241_" class="st2" x1="34.5" y1="26.8" x2="34.5" y2="44.6"/>
<line id="XMLID_285_" class="st2" x1="42.4" y1="26.8" x2="42.4" y2="44.6"/>
<line id="XMLID_279_" class="st2" x1="47.1" y1="26.8" x2="47.1" y2="44.6"/>
<path id="XMLID_287_" class="st2" d="M7.9,23.9l24-14.4c0,0,0,0,0,0l24.2,14.2"/>
<path id="XMLID_290_" class="st2" d="M30.2,14.9l-9.4,5.6c-0.1,0.1-0.1,0.2,0.1,0.2h18"/>
</g>
<g id="XMLID_341_" class="st0">
<g id="XMLID_334_" class="st3">
<path id="XMLID_299_" class="st6" d="M32.3,53.9H12.5c-0.2,0-0.4-0.2-0.4-0.4V25.2c0-0.2,0.2-0.4,0.4-0.4h19.8
c0.2,0,0.4,0.2,0.4,0.4v28.3C32.7,53.7,32.5,53.9,32.3,53.9z"/>
<polyline id="XMLID_305_" class="st6" points="29.7,33.4 15.1,33.4 15.1,28.1"/>
<line id="XMLID_306_" class="st6" x1="15.8" y1="39.3" x2="17.4" y2="39.3"/>
<line id="XMLID_304_" class="st6" x1="19.6" y1="39.3" x2="21.2" y2="39.3"/>
<line id="XMLID_307_" class="st6" x1="23.4" y1="39.3" x2="25.1" y2="39.3"/>
<line id="XMLID_312_" class="st6" x1="27.2" y1="39.3" x2="28.9" y2="39.3"/>
<line id="XMLID_323_" class="st6" x1="15.8" y1="42.6" x2="17.5" y2="42.6"/>
<line id="XMLID_322_" class="st6" x1="19.7" y1="42.6" x2="21.3" y2="42.6"/>
<line id="XMLID_321_" class="st6" x1="23.5" y1="42.6" x2="25.2" y2="42.6"/>
<line id="XMLID_320_" class="st6" x1="27.3" y1="42.6" x2="29" y2="42.6"/>
<line id="XMLID_329_" class="st6" x1="15.9" y1="45.9" x2="17.6" y2="45.9"/>
<line id="XMLID_327_" class="st6" x1="19.8" y1="45.9" x2="21.4" y2="45.9"/>
<line id="XMLID_326_" class="st6" x1="23.6" y1="45.9" x2="25.3" y2="45.9"/>
<line id="XMLID_324_" class="st6" x1="27.4" y1="45.9" x2="29.1" y2="45.9"/>
<line id="XMLID_333_" class="st6" x1="16" y1="49.1" x2="17.7" y2="49.1"/>
<line id="XMLID_332_" class="st6" x1="19.9" y1="49.1" x2="21.5" y2="49.1"/>
<line id="XMLID_331_" class="st6" x1="23.7" y1="49.1" x2="25.4" y2="49.1"/>
<line id="XMLID_330_" class="st6" x1="27.5" y1="49.1" x2="29.2" y2="49.1"/>
</g>
<path id="XMLID_336_" class="st2" d="M20.3,20.9c1.3-6.2,6.7-10.8,13.2-10.8c7.5,0,13.5,6,13.5,13.5c0,7-5.3,12.7-12.1,13.4"/>
<path id="XMLID_343_" class="st7" d="M51.9,23.7c0,9.5-7.2,17.3-16.4,18.3"/>
<polyline id="XMLID_337_" class="st2" points="33.7,11 33.7,23.6 46.8,23.6"/>
<line id="XMLID_342_" class="st2" x1="43.1" y1="23.6" x2="46.6" y2="20.1"/>
<line id="XMLID_335_" class="st2" x1="38.4" y1="23.4" x2="45.1" y2="16.7"/>
<line id="XMLID_338_" class="st2" x1="33.9" y1="23" x2="42.9" y2="14"/>
<line id="XMLID_339_" class="st2" x1="33.8" y1="18.3" x2="40.2" y2="11.9"/>
<line id="XMLID_340_" class="st2" x1="33.6" y1="13.6" x2="36.5" y2="10.6"/>
</g>
<g id="XMLID_380_" class="st0">
<g id="XMLID_346_" class="st3">
<path id="XMLID_357_" class="st6" d="M23.8,37.6v-1.9c0-0.4,0.3-0.8,0.6-0.9l5.2-1.8v-2.9"/>
<path id="XMLID_355_" class="st6" d="M40.2,37.6v-1.9c0-0.4-0.3-0.8-0.6-0.9L34.3,33v-2.9"/>
<path id="XMLID_354_" class="st6" d="M36,23.5c0-2.2-1.8-4-4-4c-2.2,0-4,1.8-4,4l0.4,4.7c0,0.6,0.4,1.2,1,1.5l1.9,1.3h1.3l2-1.3
c0.6-0.3,0.9-0.9,1-1.5L36,23.5z"/>
<polyline id="XMLID_347_" class="st6" points="29.7,33 31.2,34.7 32.9,34.7 34.4,33"/>
</g>
<path id="XMLID_345_" class="st2" d="M47.3,28.6c0,2-1.9,3.5-2.6,5.3c-0.7,1.8-0.6,4.2-1.9,5.5c-1.3,1.3-3.8,1.2-5.5,1.9
C35.5,42,34,43.8,32,43.8s-3.5-1.9-5.3-2.6c-1.8-0.7-4.2-0.6-5.5-1.9c-1.3-1.3-1.2-3.8-1.9-5.5c-0.7-1.7-2.6-3.3-2.6-5.3
c0-2,1.9-3.5,2.6-5.3c0.7-1.8,0.6-4.2,1.9-5.5c1.3-1.3,3.8-1.2,5.5-1.9c1.7-0.7,3.3-2.6,5.3-2.6s3.5,1.9,5.3,2.6
c1.8,0.7,4.2,0.6,5.5,1.9c1.3,1.3,1.2,3.8,1.9,5.5C45.4,25,47.3,26.6,47.3,28.6z"/>
<path id="XMLID_349_" class="st2" d="M38.9,43.2v11.1c0,0.1-0.1,0.1-0.1,0.1L32,51.3l-6.8,3.1c0,0-0.1,0-0.1-0.1V43.2"/>
<path id="XMLID_350_" class="st7" d="M20.3,43.4c-4.4-3.5-7.2-8.8-7.2-14.9c0-10.4,8.5-18.9,18.9-18.9s18.9,8.5,18.9,18.9
c0,6-2.8,11.4-7.2,14.9"/>
</g>
<g id="XMLID_374_" class="st0">
<path id="XMLID_358_" class="st2" d="M42.4,50v-3.5c0-0.1-0.1-0.1-0.1-0.1h-21c-0.1,0-0.1,0.1-0.1,0.1V50h-5.5
c-0.1,0-0.1,0.1-0.1,0.1v4.1h32.6v-4.1c0-0.1-0.1-0.1-0.1-0.1H42.4z"/>
<line id="XMLID_352_" class="st2" x1="23.5" y1="50" x2="33.3" y2="50"/>
<path id="XMLID_361_" class="st2" d="M29.3,12.9v-0.6c0-1.4,1.2-2.6,2.6-2.6l0,0c1.4,0,2.6,1.2,2.6,2.6v0.6"/>
<line id="XMLID_372_" class="st2" x1="30.2" y1="45.4" x2="30.2" y2="22.1"/>
<line id="XMLID_360_" class="st2" x1="33.6" y1="22.1" x2="33.6" y2="45.4"/>
<circle id="XMLID_353_" class="st2" cx="31.9" cy="18.1" r="4.3"/>
<path id="XMLID_363_" class="st2" d="M28.7,18.1h-8.8c0,1.2-1,2.3-2.3,2.3c-1.2,0-2.3-1-2.3-2.3v-1"/>
<path id="XMLID_362_" class="st2" d="M35.5,18.1h8.8c0,1.2,1,2.3,2.3,2.3c1.2,0,2.3-1,2.3-2.3v-1"/>
<line id="XMLID_364_" class="st2" x1="18.6" y1="20.3" x2="23.6" y2="31.5"/>
<line id="XMLID_369_" class="st2" x1="16.6" y1="20.3" x2="11.6" y2="31.5"/>
<path id="XMLID_366_" class="st2" d="M10.9,31.6c0,3.6,3,6.6,6.6,6.6s6.6-3,6.6-6.6H10.9z"/>
<line id="XMLID_371_" class="st2" x1="45.4" y1="20.3" x2="40.4" y2="31.5"/>
<line id="XMLID_368_" class="st2" x1="47.4" y1="20.3" x2="52.4" y2="31.5"/>
<path id="XMLID_365_" class="st2" d="M53.1,31.6c0,3.6-3,6.6-6.6,6.6s-6.6-3-6.6-6.6H53.1z"/>
<line id="XMLID_373_" class="st2" x1="30.9" y1="18.1" x2="32.9" y2="18.1"/>
</g>
<g id="XMLID_402_" class="st0">
<path id="XMLID_398_" class="st2" d="M17.1,47.1h-6.8c-0.1,0-0.1,0-0.1-0.1V17.6c0-0.1,0-0.1,0.1-0.1h39.8c0.1,0,0.1,0,0.1,0.1V21
"/>
<line id="XMLID_399_" class="st2" x1="37.1" y1="30.7" x2="50.9" y2="30.7"/>
<line id="XMLID_396_" class="st2" x1="37.1" y1="34.7" x2="50.9" y2="34.7"/>
<line id="XMLID_400_" class="st2" x1="37.1" y1="38.7" x2="50.9" y2="38.7"/>
<line id="XMLID_401_" class="st2" x1="37.1" y1="42.6" x2="44.7" y2="42.6"/>
<path id="XMLID_405_" class="st2" d="M47.8,22.2H34.3c0,0-0.1,0-0.1,0.1v24.6c0,0,0,0.1,0.1,0.1h19.4c0,0,0.1,0,0.1-0.1V28.2
L47.8,22.2z"/>
<path id="XMLID_397_" class="st2" d="M47.8,22.2v5.9c0,0,0,0.1,0.1,0.1h5.9"/>
<polyline id="XMLID_404_" class="st2" points="20.6,28.8 20.6,32.3 17.2,32.3 17.2,28.8"/>
<path id="XMLID_406_" class="st2" d="M21.1,17.3v-5.2c0-0.6,0.4-1,1-1h15.6c0.6,0,1,0.4,1,1v5.2"/>
<line id="XMLID_408_" class="st2" x1="10.2" y1="29.2" x2="32.1" y2="29.2"/>
<g id="XMLID_746_" class="st3">
<polygon id="XMLID_735_" class="st6" points="48.3,49.3 50.4,51.1 48.3,52.8 19,52.8 19,49.3"/>
<line id="XMLID_741_" class="st6" x1="15.6" y1="51.1" x2="18.6" y2="51.1"/>
<line id="XMLID_743_" class="st6" x1="49.8" y1="51.1" x2="52.7" y2="51.1"/>
<line id="XMLID_742_" class="st6" x1="36" y1="52.9" x2="36" y2="49.3"/>
<line id="XMLID_736_" class="st6" x1="45.7" y1="52.9" x2="45.7" y2="49.3"/>
<polyline id="XMLID_744_" class="st6" points="21.1,49.3 21.1,46.9 30.7,46.9"/>
</g>
</g>
<g id="XMLID_409_" class="st0">
<g id="XMLID_288_" class="st3">
<g id="XMLID_300_">
<path id="XMLID_303_" class="st6" d="M34.9,14.9h-4.3c-1.2,0-2.1,1-2.1,2.1l0,0c0,1.2,1,2.1,2.1,2.1h2.1c1.2,0,2.1,1,2.1,2.1
l0,0c0,1.2-1,2.1-2.1,2.1h-4.5"/>
<line id="XMLID_302_" class="st6" x1="31.7" y1="12.7" x2="31.7" y2="14.9"/>
<line id="XMLID_301_" class="st6" x1="31.7" y1="23.5" x2="31.7" y2="25.6"/>
</g>
<circle id="XMLID_294_" class="st6" cx="31.5" cy="19.1" r="9"/>
</g>
<path id="XMLID_420_" class="st2" d="M40.4,28.3c1.3-3.4,4.3-4,5.5-4.5c0.2-0.1,0.4,0.1,0.4,0.3L44.4,31"/>
<path id="XMLID_416_" class="st2" d="M23.9,27.3c-3.3,2.4-5.4,6-5.4,10.1c0,3.2,1.3,6.2,3.6,8.4v7.9c0,0.1,0,0.1,0.1,0.1h7.3
c0.1,0,0.1,0,0.1-0.1v-3.8c1.3,0.3,2.6,0.5,3.9,0.5c0.8,0,1.7-0.1,2.5-0.2v3.5c0,0.1,0,0.1,0.1,0.1h7.3c0.1,0,0.1,0,0.1-0.1v-6.6
c1.6-1.2,2.8-2.7,3.7-4.3c0.3-0.6,1-1,1.7-1h2.2c0,0,0,0,0,0v-6.1c0,0,0,0,0,0h-1.3c-0.8,0-1.5-0.6-1.7-1.3
c-0.5-1.8-1.5-3.5-2.8-4.9"/>
<path id="XMLID_414_" class="st2" d="M41.7,26.4c-0.5-0.3-1.1-0.6-1.6-0.8"/>
<line id="XMLID_419_" class="st2" x1="26" y1="31.6" x2="38.6" y2="31.6"/>
<path id="XMLID_411_" class="st2" d="M18.5,37.3c-0.9,1.2-2.3,2-3.8,1.9c-0.2,0-0.4,0-0.6-0.1c-0.3-0.2-0.4-0.5-0.6-0.8
c-0.2-0.5-0.5-1.1-0.4-1.7c0-0.6,0.3-1.2,0.9-1.4c0.5-0.2,1.1,0,1.5,0.4c0.4,0.4,0.5,1,0.5,1.5c0,1.7-1.3,3.3-2.9,3.6"/>
<line id="XMLID_415_" class="st2" x1="39.1" y1="35.1" x2="41.8" y2="35.1"/>
</g>
<g id="XMLID_445_" class="st0">
<path id="XMLID_428_" class="st2" d="M33.6,33.4l4.8-0.9c1.6,0.7,3.3,1,5.2,0.7c4.2-0.6,7.6-4,8.1-8.2c0.7-6.1-4.5-11.3-10.6-10.5
c-4.2,0.5-7.6,3.9-8.2,8.2c-0.3,2.3,0.3,4.5,1.4,6.2l-0.8,4.4C33.5,33.4,33.6,33.4,33.6,33.4z"/>
<g id="XMLID_421_" class="st3">
<path id="XMLID_747_" class="st6" d="M36.4,45.7h3.4c1.1,0,2-0.9,2-2l0,0c0-1.1-0.9-2-2-2h-3.4"/>
<path id="XMLID_431_" class="st6" d="M35,49.6h2.7c1.1,0,2-0.9,2-2l0,0c0-1.1-0.9-2-2-2H35"/>
<path id="XMLID_745_" class="st6" d="M34.8,49.6h0.8c1.1,0,2,0.9,2,2l0,0c0,1.1-0.9,2-2,2H24.4c-0.5,0-1-0.2-1.3-0.6l-2-2
c-0.3-0.3-0.7-0.5-1.1-0.5h-1.2"/>
<path id="XMLID_430_" class="st6" d="M35,41.7h4c1.1,0,2-0.9,2-2l0,0c0-1.1-0.9-2-2-2h-7.5h-4.7c0,0,1.6-4.1,1.6-6
c0-0.9,0.2-2.7-0.3-3.5c-0.2-0.3-1.8-0.3-2,0c0,0-1.5,4.6-2.5,6c-0.6,0.8-2.7,3.6-2.7,3.6h-1.3"/>
<path id="XMLID_423_" class="st6" d="M8.2,35.7H19c0.1,0,0.1,0,0.1,0.1v16.5c0,0.1,0,0.1-0.1,0.1H8.2"/>
</g>
<path id="XMLID_441_" class="st2" d="M42.3,10.5c7.4,0,13.4,6,13.4,13.4s-6,13.4-13.4,13.4"/>
<path id="XMLID_440_" class="st7" d="M28.9,23.9c0-7.4,6-13.4,13.4-13.4"/>
<line id="XMLID_442_" class="st2" x1="37.3" y1="20.8" x2="47.1" y2="20.8"/>
<line id="XMLID_439_" class="st2" x1="37.4" y1="23.9" x2="47.2" y2="23.9"/>
<line id="XMLID_444_" class="st2" x1="37.5" y1="27.1" x2="42.4" y2="27.1"/>
</g>
</g>
<g id="Icon_text" class="st0">
<text id="XMLID_15_" transform="matrix(1 0 0 1 -158.0612 -73.1667)" class="st9 st10" style="display:inline;fill:#4C5159;">IDEA</text>
<g id="XMLID_65_" class="st3">
<text id="XMLID_432_" transform="matrix(1 0 0 1 -76.9106 -73.1667)" class="st8 st9 st10">ANALYSIS</text>
</g>
<g id="XMLID_66_" class="st3">
<text id="XMLID_433_" transform="matrix(1 0 0 1 5.1667 -73.1667)" class="st8 st9 st10">M-COMMERCE</text>
</g>
<g id="XMLID_101_" class="st3">
<text id="XMLID_434_" transform="matrix(1 0 0 1 109.0616 -73.167)" class="st8 st9 st10">MONEY</text>
</g>
<g id="XMLID_103_" class="st3">
<text id="XMLID_435_" transform="matrix(1 0 0 1 197.1454 -73.1669)"><tspan x="0" y="0" class="st8 st9 st10">PROJECT</tspan><tspan x="-10.8" y="7" class="st8 st9 st10">DEVELOPMENT</tspan></text>
</g>
<g id="XMLID_118_" class="st3">
<text id="XMLID_436_" transform="matrix(1 0 0 1 -166.5963 21.0416)" class="st8 st9 st10">GROWTH</text>
</g>
<g id="XMLID_138_" class="st3">
<text id="XMLID_437_" transform="matrix(1 0 0 1 -77.1425 21.0416)"><tspan x="0" y="0" class="st8 st9 st10">BUSINESS</tspan><tspan x="5.3" y="7" class="st8 st9 st10">VISION</tspan></text>
</g>
<g id="XMLID_134_" class="st3">
<text id="XMLID_438_" transform="matrix(1 0 0 1 23.2642 21.0416)"><tspan x="0" y="0" class="st8 st9 st10">IDEA</tspan><tspan x="-18.8" y="7" class="st8 st9 st10">DEVELOPMENT</tspan></text>
</g>
<g id="XMLID_168_" class="st3">
<text id="XMLID_447_" transform="matrix(1 0 0 1 104.3105 21.0418)" class="st8 st9 st10">MANAGER</text>
</g>
<g id="XMLID_176_" class="st3">
<text id="XMLID_448_" transform="matrix(1 0 0 1 202.4471 21.0416)"><tspan x="0" y="0" class="st8 st9 st10">VIDEO</tspan><tspan x="-6.2" y="7" class="st8 st9 st10">MESSAGE</tspan></text>
</g>
<g id="XMLID_185_" class="st3">
<text id="XMLID_449_" transform="matrix(1 0 0 1 -168.2577 117.6666)" class="st8 st9 st10">STRATEGY</text>
</g>
<g id="XMLID_215_" class="st3">
<text id="XMLID_450_" transform="matrix(1 0 0 1 -75.9106 117.667)" class="st8 st9 st10">SERVICES</text>
</g>
<g id="XMLID_227_" class="st3">
<text id="XMLID_451_" transform="matrix(1 0 0 1 14.6032 117.667)" class="st8 st9 st10">SUPPORT</text>
</g>
<g id="XMLID_237_" class="st3">
<text id="XMLID_452_" transform="matrix(1 0 0 1 111.2283 117.6666)" class="st8 st9 st10">VALUE</text>
</g>
<g id="XMLID_260_" class="st3">
<text id="XMLID_466_" transform="matrix(1 0 0 1 196.2038 117.6666)"><tspan x="0" y="0" class="st8 st9 st10">PREMIUM</tspan><tspan x="2.1" y="7" class="st8 st9 st10">QUALITY</tspan></text>
</g>
<g id="XMLID_56_" class="st3">
<text id="XMLID_467_" transform="matrix(1 0 0 1 -158.3332 211.1663)"><tspan x="0" y="0" class="st8 st9 st10">KEY </tspan><tspan x="-10.9" y="7" class="st8 st9 st10">SOLUTION</tspan></text>
</g>
<g id="XMLID_291_" class="st3">
<text id="XMLID_468_" transform="matrix(1 0 0 1 -69.1653 211.167)" class="st8 st9 st10">BANK</text>
</g>
<g id="XMLID_295_" class="st3">
<text id="XMLID_469_" transform="matrix(1 0 0 1 22.0008 211.167)" class="st8 st9 st10">LOAN</text>
</g>
<g id="XMLID_344_" class="st3">
<text id="XMLID_470_" transform="matrix(1 0 0 1 101.2337 211.1671)" class="st8 st9 st10">ACCOUTING</text>
</g>
<g id="XMLID_348_" class="st3">
<text id="XMLID_471_" transform="matrix(1 0 0 1 194.6282 211.1666)"><tspan x="0" y="0" class="st8 st9 st10">EMPLOYEE</tspan><tspan x="-8.2" y="7" class="st8 st9 st10">OF THE MONTH</tspan></text>
</g>
<g id="XMLID_351_" class="st3">
<text id="XMLID_472_" transform="matrix(1 0 0 1 -161.4352 307.1667)" class="st8 st9 st10">SCALE</text>
</g>
<g id="XMLID_395_" class="st3">
<text id="XMLID_473_" transform="matrix(1 0 0 1 -80.9337 307.1663)" class="st8 st9 st10">TEAMWORK</text>
</g>
<g id="XMLID_403_" class="st3">
<text id="XMLID_474_" transform="matrix(1 0 0 1 11.0073 307.1667)" class="st8 st9 st10">PORTFOLIO</text>
</g>
<g id="XMLID_418_" class="st3">
<text id="XMLID_475_" transform="matrix(1 0 0 1 112.2521 307.1667)"><tspan x="0" y="0" class="st8 st9 st10">PIGGY</tspan><tspan x="0.6" y="7" class="st8 st9 st10">BANK</tspan></text>
</g>
<g id="XMLID_446_" class="st3">
<text id="XMLID_476_" transform="matrix(1 0 0 1 187.8177 307.1667)"><tspan x="0" y="0" class="st8 st9 st10">SOCIAL MEDIA</tspan><tspan x="6.3" y="7" class="st8 st9 st10">CAMPAIGN</tspan></text>
</g>
</g>
<g id="headline" class="st0">
<circle id="XMLID_61_" class="st11" cx="-133.5" cy="-208.4" r="18.3"/>
<circle id="XMLID_60_" class="st12" cx="-110.1" cy="-208.4" r="18.3"/>
<circle id="XMLID_63_" class="st13" cx="-122.7" cy="-190.1" r="18.3"/>
<g id="XMLID_4_" class="st3">
<text id="XMLID_477_" transform="matrix(1 0 0 1 -183.7552 -194.0852)"><tspan x="0" y="0" style="fill:#231F20; font-family:'Oswald-Bold'; font-size:22.512px;">FLAT </tspan><tspan x="45.5" y="0" style="fill:#231F20; font-family:'Oswald-Light'; font-size:22.512px;">LINE </tspan><tspan x="83.9" y="0" style="fill:#231F20; font-family:'Oswald-Bold'; font-size:22.512px;">ICONS</tspan></text>
</g>
<g id="XMLID_1_" class="st3">

<text id="XMLID_478_" transform="matrix(1 0 0 1 127.3388 -193.9056)" style="fill:#231F20; font-family:'Oswald-Light'; font-size:16.1735px;">BUSINESS &amp; FINANCE</text>
</g>
</g>
</svg>

</template>

<script>
export default {
  name: 'Email'
}
</script>

<style scoped>
.st0{display:none;}
.st1{display:inline;fill:#FF8000;}
.st2{display:inline;fill:none;stroke:#FF8000;stroke-width:2;stroke-miterlimit:10;}
.st3{display:inline;}
.st4{fill:none;stroke:#FF8000;stroke-width:2;stroke-miterlimit:10;stroke-dasharray:1.9857,1.9857;}
.st5{display:inline;fill:none;stroke:#FF8000;stroke-width:2;stroke-miterlimit:10;stroke-dasharray:1.9857,1.9857;}
.st6{fill:none;stroke:#FF8000;stroke-width:2;stroke-miterlimit:10;}
.st7{display:inline;fill:none;stroke:#FF8000;stroke-width:2;stroke-miterlimit:10;stroke-dasharray:1.986,1.9857;}
.st8{fill:#FF8000;}
.st9{font-family:'Montserrat-Bold';}
.st10{font-size:7px;}
.st11{display:inline;fill:#FF8000;}
.st12{display:inline;fill:#FF8000;}
.st13{display:inline;fill:#FF8000;}
</style>
