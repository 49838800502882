<template>
  <svg width="64" height="64" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
    <g id="Birthday_Hat">
      <path d="M363.5007,352.4461,291.0371,186.8792a7.7539,7.7539,0,0,0-1.4092-3.22l-14.9463-34.15a30.7955,30.7955,0,1,0-39.77,0L145.9244,352.8292a32.26,32.26,0,1,0,26.7914,56.3453,32.2624,32.2624,0,0,0,41.6426,0,32.2611,32.2611,0,0,0,41.6416,0,32.2611,32.2611,0,0,0,41.6416,0,32.2624,32.2624,0,0,0,41.6426,0,32.2628,32.2628,0,1,0,24.2165-56.7284ZM215.5732,233.6011l63.5684-33.9953,18.4956,42.26L185.7734,301.6883Zm-40.1677,91.776,128.6668-68.8086,18.35,41.9273-110.8512,59.281a32.2619,32.2619,0,0,0-38.8554,2.1087,32,32,0,0,0-9.9057-5.73Zm171.7359,29.5975a32.0627,32.0627,0,0,0-7.8572,4.91,32.2608,32.2608,0,0,0-41.6426.001,32.2578,32.2578,0,0,0-41.6416,0,32.14,32.14,0,0,0-6.4736-4.2621l79.331-42.4246ZM254.7965,156.7985a30.9521,30.9521,0,0,0,5.3989-.4815l12.5111,28.5859-46.7653,25.0094,23.457-53.5952A30.9453,30.9453,0,0,0,254.7965,156.7985Z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Birthday'
}
</script>
